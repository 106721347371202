<template>
  <div class="search-box">
    <div class="title">查詢方式</div>
    <div class="container">
      <a-radio-group v-model:value="searchData.searchType" button-style="solid">
        <a-radio-button value="basic">個案資料</a-radio-button>
        <a-radio-button value="healthyStatus">健康狀態</a-radio-button>
      </a-radio-group>
    </div>
    <div class="title">查詢條件</div>
    <div class="container" v-if="searchData.searchType === 'basic'">
      <a-radio-group
        v-model:value="searchData.searchItem"
        :options="searchOptions"
      />
      <br />
      <a-input
        v-model:value="searchData.searchValue"
        placeholder=""
        style="width: 300px; margin-top: 10px"
      />
    </div>
    <div v-if="searchData.searchType === 'healthyStatus'" class="container">
      <div style="margin-top: 10px">
        量測日期
        <a-range-picker
          :format="dateFormat"
          style="margin-left: 20px"
          v-model:value="searchData.date"
        />
      </div>
      <div style="margin-top: 10px">
        健康項目
        <a-select
          v-model:value="searchData.type"
          style="width: 120px; margin-left: 20px"
          @change="
            () => {
              searchData.caseSettingId = ''
            }
          "
        >
          <a-select-option value="" disabled>請選擇</a-select-option>
          <a-select-option
            v-for="item in measureType"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <a-select
          v-model:value="searchData.caseSettingId"
          style="width: 500px; margin-left: 20px"
          v-if="searchData.type != ''"
        >
          <a-select-option
            v-for="item in getSettingItem()"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <div style="margin-left: 220px">
          {{ getRuleContent() }}
        </div>
      </div>
    </div>
    <a-button type="primary" @click="handleSearch" :disabled="!chackCanSearch">
      查詢
    </a-button>
  </div>
</template>

<script>
  import { onBeforeMount, reactive, ref, watch } from 'vue'
  import moment from 'moment'
  import { searchSettingList } from '@/api/searchSetting'
  import { measureString, CaseRulesString } from '@/utils/index'

  export default {
    props: ['filter'],
    components: {},
    methods: {
      moment,
    },
    setup(props, { emit }) {
      const searchType = ref('basic')
      const searchData = reactive(props.filter)
      const chackCanSearch = ref(false) // 是否可以送出
      const searchOptions = [
        { label: '姓名', value: 'name' },
        { label: '手機', value: 'phone' },
        { label: '會員卡號', value: 'cardID' },
      ]
      const measureType = ref([])
      let caseSettingList = []

      onBeforeMount(async () => {
        const { data } = await searchSettingList()
        caseSettingList = data.result

        // 取得健康項目清單
        for (const key in caseSettingList) {
          // 此項目是否有在清單中
          const hasItem = measureType.value.some(
            (item) => item.value == caseSettingList[key].type
          )
          //如果沒有則加入
          if (!hasItem) {
            // 預設選取第一項
            // if (
            //   searchData.caseSettingId == undefined ||
            //   searchData.type == ''
            // ) {
            //   searchData.type = obj.type
            // }
            measureType.value.push({
              label: measureString(caseSettingList[key].type),
              value: caseSettingList[key].type,
            })
          }
          caseSettingList[key].content = CaseRulesString(
            caseSettingList[key].clauses
          )
        }
      })

      // 以量測類別取得規則
      const getSettingItem = () => {
        return caseSettingList
          .filter((item) => {
            return searchData.type === item.type
          })
          .map((item) => {
            // 改變類別後預設選取第一項
            if (searchData.caseSettingId == '') {
              searchData.caseSettingId = item.id
            }
            return {
              label: item.title,
              value: item.id,
            }
          })
      }

      // 以規則ID取得篩選內容
      const getRuleContent = () => {
        if (
          searchData.caseSettingId !== undefined &&
          searchData.caseSettingId != ''
        ) {
          return caseSettingList.find(
            (item) => item.id == searchData.caseSettingId
          ).content
        }
      }

      const handleSearch = () => {
        emit('search', { ...searchData })
      }

      // 檢查是否可以送出查詢
      const chackSearch = () => {
        let pass = true
        if (
          searchData.searchType == 'basic' &&
          (searchData.searchItem == '' || searchData.searchValue == '')
        ) {
          pass = false
        }
        if (
          searchData.searchType == 'healthyStatus' &&
          (searchData.type == '' || searchData.caseSettingId == '')
        ) {
          pass = false
        }
        chackCanSearch.value = pass
      }
      watch(
        () => searchData,
        () => {
          chackSearch()
        },
        {
          deep: true,
        }
      )
      return {
        searchType,
        dateFormat: 'YYYY/MM/DD',
        searchOptions,
        measureType,
        searchData,
        handleSearch,
        getSettingItem,
        getRuleContent,
        chackSearch,
        chackCanSearch,
      }
    },
  }
</script>

<style lang="less">
  .search-box {
    .container {
      margin-left: 60px;
    }
    background-color: #fff;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    padding: 20px;

    .ant-collapse-content {
      background-color: #fff;
    }

    button {
      margin: 10px;
    }
  }
</style>
