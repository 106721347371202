import { render } from "./Search.vue?vue&type=template&id=003e460e"
import script from "./Search.vue?vue&type=script&lang=js"
export * from "./Search.vue?vue&type=script&lang=js"

import "./Search.vue?vue&type=style&index=0&id=003e460e&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "003e460e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('003e460e', script)) {
    api.reload('003e460e', script)
  }
  
  module.hot.accept("./Search.vue?vue&type=template&id=003e460e", () => {
    api.rerender('003e460e', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/Search.vue"

export default script