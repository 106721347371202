import { render } from "./CaseDataTable.vue?vue&type=template&id=3d62a3fe"
import script from "./CaseDataTable.vue?vue&type=script&lang=js"
export * from "./CaseDataTable.vue?vue&type=script&lang=js"

import "./CaseDataTable.vue?vue&type=style&index=0&id=3d62a3fe&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3d62a3fe"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3d62a3fe', script)) {
    api.reload('3d62a3fe', script)
  }
  
  module.hot.accept("./CaseDataTable.vue?vue&type=template&id=3d62a3fe", () => {
    api.rerender('3d62a3fe', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/CaseDataTable.vue"

export default script