<template>
  <div class="caseDataTable-component">
    <a-anchor>
      <a-row
        type="flex"
        justify="space-between"
        align="middle"
        id="tableAction"
        style="margin-top: 6px"
      >
        <a-button
          type="primary"
          @click="exportExcel"
          :disabled="!props.exportEnable"
        >
          匯出(.xlsx)
        </a-button>
        <div v-if="data.length > 0" style="margin-right: 200px">
          總共筆數：{{ dataLength }}
        </div>
      </a-row>
      <a-table
        :pagination="tableSetting"
        :bordered="true"
        :columns="filterTableColumns"
        :data-source="data"
        :scroll="{ x: 600, y: windowHeight - 106 }"
        :key="componentKey"
        :rowKey="(record) => record.memberId"
        :loading="props.loading"
      >
        <template #action="{ text }">
          <a-list size="small">
            <a-list-item v-if="text.action1">
              <a @click="action1(text.memberId)">
                {{ text.action1 }}
              </a>
            </a-list-item>
            <a-list-item v-if="text.action2">
              <a @click="action2(text.memberId)">
                {{ text.action2 }}
              </a>
            </a-list-item>
            <a-list-item v-if="text.action3">
              <a @click="action3(text.memberId)">
                {{ text.action3 }}
              </a>
            </a-list-item>
            <a-list-item v-if="text.action4">
              <a @click="action4(text.memberId)">
                {{ text.action4 }}
              </a>
            </a-list-item>
          </a-list>
        </template>
      </a-table>
    </a-anchor>
  </div>
</template>

<script>
  import { reactive, computed, ref, watch, onMounted } from 'vue'
  import { numberComma } from '@/utils/index'
  export default {
    props: {
      searchResult: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      },
      selectType: {
        type: String,
        default: 'basic',
      },
      exportEnable: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const data = computed(() => props.searchResult)
      const dataLength = computed(() => numberComma(data.value.length))
      const componentKey = ref(1)
      const windowHeight = window.innerHeight
      const tableSetting = reactive({
        currentPage: 1,
        pageSize: 20,
        position: 'top',
        simple: true,
      })

      const filterTableColumns = ref([])
      const tableColumns = [
        {
          title: '會員編號',
          dataIndex: 'memberId',
          key: 'memberId',
          align: 'center',
          group: 'fixed',
        },
        {
          title: '會員卡號',
          dataIndex: 'cardId',
          key: 'cardId',
          align: 'center',
          group: 'fixed',
          customRender: ({ text }) => text.substring(0, 10),
        },
        {
          title: '姓名',
          key: 'name',
          align: 'center',
          dataIndex: 'name',
          group: 'fixed',
        },
        {
          title: '性別',
          key: 'gender',
          align: 'center',
          dataIndex: 'gender',
          group: 'basic',
          customRender: ({ text }) => {
            let rtnText = ''
            switch (text) {
              case 'M':
                rtnText = '男'
                break
              case 'F':
                rtnText = '女'
                break
            }
            return rtnText
          },
        },
        {
          title: '聯絡電話',
          key: 'phoneNumber',
          align: 'center',
          dataIndex: 'phoneNumber',
          group: 'basic',
        },
        {
          title: '行動電話',
          key: 'phoneNumber',
          align: 'center',
          dataIndex: 'phoneNumber',
          group: 'basic',
        },
        {
          title: '服務狀態',
          key: 'hasLifeService',
          align: 'center',
          type: 'basic',
          dataIndex: 'hasLifeService',
          customRender: ({ text }) => (text ? '進行中' : '無進行中合約'),
          group: 'basic',
        },
        {
          title: '責任健管師',
          key: 'useQuota',
          align: 'center',
          dataIndex: 'useQuota',
          group: 'basic',
        },
        {
          title: '量測次數',
          dataIndex: 'frequency',
          align: 'center',
          group: 'healthyStatus',
        },
        {
          title: '平均收縮壓(毫米汞柱)',
          dataIndex: 'avgRecord.systolic',
          align: 'center',
          group: 'bloodPressure',
        },
        {
          title: '平均舒張壓(毫米汞柱)',
          dataIndex: 'avgRecord.diastolic',
          align: 'center',
          group: 'bloodPressure',
        },
        {
          title: '平均脈搏(次/分鐘)',
          dataIndex: 'avgRecord.pulse',
          align: 'center',
          group: 'bloodPressure',
        },
        {
          title: '平均脈壓差(毫米汞柱)',
          dataIndex: 'avgRecord.pulsePressureDifference',
          align: 'center',
          group: 'bloodPressure',
        },
        {
          title: '心律不整次數',
          dataIndex: 'avgRecord.arr',
          align: 'center',
          group: 'bloodPressure',
        },
        {
          title: '平均體重',
          dataIndex: 'avgRecord.weight',
          align: 'center',
          group: 'weight',
        },
        {
          title: '平均BMI',
          dataIndex: 'avgRecord.bmi',
          align: 'center',
          group: 'weight',
        },
        {
          title: '平均基礎代謝率',
          dataIndex: 'avgRecord.bmr',
          align: 'center',
          group: 'weight',
        },
        {
          title: '平均體脂率',
          dataIndex: 'avgRecord.visceralFat',
          align: 'center',
          group: 'weight',
        },
        {
          title: '平均肌肉率',
          dataIndex: 'avgRecord.muscleMass',
          align: 'center',
          group: 'weight',
        },
        {
          title: '平均內臟脂肪',
          dataIndex: 'avgRecord.bodyFat',
          align: 'center',
          group: 'weight',
        },
        {
          title: '平均血氧(最低%)',
          dataIndex: 'avgRecord.SpO2',
          align: 'center',
          group: 'SpO2',
        },
        {
          title: '平均體溫(度)',
          dataIndex: 'avgRecord.temperature',
          align: 'center',
          group: 'bodyTemperature',
        },
        {
          title: '平均空腹血糖(毫克/分升)',
          // dataIndex: 'avgRecord.bloodGlucose',
          customRender: ({ record }) =>
            record.avgRecord.meal == 'Fasting'
              ? record.avgRecord.bloodGlucose
              : '',
          align: 'center',
          group: 'bloodGlucose',
        },
        {
          title: '平均飯前血糖(毫克/分升)',
          // dataIndex: 'avgRecord.bloodGlucoseBeforeMeal',
          customRender: ({ record }) =>
            record.avgRecord.meal == 'Before Meal'
              ? record.avgRecord.bloodGlucose
              : '',
          align: 'center',
          group: 'bloodGlucose',
        },
        {
          title: '平均飯後血糖(毫克/分升)',
          // dataIndex: 'avgRecord.bloodGlucoseAfterMeal',
          customRender: ({ record }) =>
            record.avgRecord.meal == 'After Meal'
              ? record.avgRecord.bloodGlucose
              : '',
          align: 'center',
          group: 'bloodGlucose',
        },
        {
          title: '',
          group: 'fixed',
          slots: { customRender: 'action' },
        },
      ]
      const changeColumns = () => {
        filterTableColumns.value = tableColumns.filter((item) => {
          if (props.selectType == 'basic') {
            return item.group == 'fixed' || item.group == 'basic'
          } else {
            return (
              item.group == 'fixed' ||
              item.group == 'healthyStatus' ||
              item.group == props.selectType
            )
          }
        })
      }
      onMounted(() => {
        changeColumns()
      })
      watch(
        () => props.searchResult,
        () => {
          changeColumns()
        },
        {
          deep: true,
        }
      )

      const action1 = (value) => {
        emit('action1', value)
      }
      const action2 = (value) => {
        emit('action2', value)
      }
      const action3 = (value) => {
        emit('action3', value)
      }
      const action4 = (value) => {
        emit('action4', value)
      }
      const exportExcel = () => {
        emit('export-excel')
      }

      return {
        tableColumns,
        filterTableColumns,
        tableSetting,
        data,
        dataLength,
        action1,
        action2,
        action3,
        action4,
        exportExcel,
        componentKey,
        windowHeight,
        props,
      }
    },
  }
</script>

<style lang="less">
  .caseDataTable-component {
    #tableAction {
      .ant-radio-group,
      button {
        z-index: 5;
      }
    }
    .ant-pagination.ant-table-pagination {
      text-align: right;
      margin: -25px 0 0 0;
    }

    .ant-table-thead > tr {
      th {
        text-align: center;
        height: 60px;
      }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 5px;
    }
  }
</style>
