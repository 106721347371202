import { render } from "./index.vue?vue&type=template&id=44476208"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "44476208"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('44476208', script)) {
    api.reload('44476208', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=44476208", () => {
    api.rerender('44476208', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/index.vue"

export default script