<template>
  <div class="caseManagement-container">
    <a-page-header title="個案管理">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
      <Search :filter="filter" @search="handleSearch" />
      <CaseDataTable
        :searchResult="searchResult"
        :loading="loading"
        :selectType="searchType"
        :exportEnable="exportEnable"
        @action1="handelAction1"
        @action2="handelAction2"
        @action3="handelAction3"
        @exportExcel="exportExcel"
      />
    </a-page-header>
  </div>
</template>

<script>
  import { reactive, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'
  import XLSX from 'xlsx'
  import export2Excel from '@/utils/export2Excel'
  import Search from './Search'
  import CaseDataTable from './CaseDataTable'
  import { search } from '@/api/cust'
  import { getCaseByRules } from '@/api/case'
  import { dateAdd, parseTime } from '@/utils/index'
  export default {
    name: 'caseManagement',
    components: {
      QuestionCircleOutlined,
      Search,
      CaseDataTable,
    },
    setup() {
      const store = useStore()
      const filter = reactive({
        searchType: 'basic',
        status: '',
        searchItem: 'phone',
        searchValue: '',
        type: '',
        date: [dateAdd(-30), dateAdd(-1)],
      })
      const searchResult = ref([])
      const router = useRouter()
      const loading = ref(false)
      const searchType = ref('basic') // 查詢類別：個案資料爲basic、健康狀態爲量測項目類別
      const searchDate = ref([]) // 匯出時使用
      const exportEnable = ref(false)

      const handleSearch = async (filter) => {
        loading.value = true
        exportEnable.value = false

        // 搜尋個案資料
        if (filter.searchType == 'basic') {
          searchType.value = 'basic'
          const { data } = await search(filter)

          // 如果是查詢會員卡號
          if (filter.searchItem == 'cardID') {
            // 如果沒有資料
            if (!data.found) {
              searchResult.value = []
            } else {
              searchResult.value = [{ ...data }]
            }
          } else {
            // 如果沒有資料
            if (typeof data === 'undefined') {
              searchResult.value = []
            } else {
              searchResult.value = data.members
            }
          }
        } else {
          searchType.value = filter.type
          searchDate.value = filter.date
          // 搜尋健康狀態
          const { data, status } = await getCaseByRules({
            ...filter,
            date: filter.date,
          })
          const dataFormated = []
          if ([200, 201].includes(status)) {
            for (const key in data.result) {
              // 避免出現memberID=null(可能是訪客)
              if (data.result[key].memberID != null) {
                // 抓取會員資料
                const custInfo = await store.dispatch('cust/getCustInfo', {
                  memberID: data.result[key].memberID,
                })

                dataFormated.push({
                  ...custInfo,
                  ...data.result[key],
                })
              }
            }

            searchResult.value = dataFormated
            if (dataFormated.length > 0) {
              exportEnable.value = true
            }
          }
        }

        // 抓取合約資料
        for (const key in searchResult.value) {
          const caseInfo = await store.dispatch('case/getCaseInfo', {
            memberID: searchResult.value[key].memberId,
          })
          const hasLifeService = caseInfo.some(
            (item) => item.serviceStatus == 1
          )

          searchResult.value[key] = {
            ...searchResult.value[key],
            hasLifeService,
          }

          searchResult.value[key].action1 = '啟用健康服務 '
          searchResult.value[key].action2 = '方案啟用記錄'
          searchResult.value[key].action3 = '瀏覽資料'
        }
        loading.value = false
      }

      const handelAction1 = (memberID) => {
        router.push({ path: `/purchaseServicePlan/${memberID}` })
      }

      const handelAction2 = (memberID) => {
        router.push({
          path: `/caseManagement/${memberID}`,
          query: { tabKey: '2' },
        })
      }
      const handelAction3 = (memberID) => {
        router.push({ path: `/caseManagement/${memberID}` })
      }

      const exportExcel = () => {
        const fixCol = ['量測日期區間', '會員編號', '會員姓名']
        let measureCol = []
        switch (searchType.value) {
          case 'bloodPressure':
            measureCol = [
              '血壓量測次數',
              '平均收縮壓',
              '平均舒張壓',
              '平均脈壓差',
              '平均脈搏',
              '心律不整(ARR)次數',
              '早期收縮(PC)次數',
              '心房顫動(Afib)次數',
            ]
            break
          case 'weight':
            measureCol = [
              '身體組成量測次數',
              '平均體重',
              '平均BMI',
              '平均體脂率',
              '平均基礎代謝率',
              '平均內臟脂肪',
              '平均肌肉率',
            ]
            break
          case 'SpO2':
            measureCol = ['血氧量測次數', '平均最低血氧']
            break
          case 'bodyTemperature':
            measureCol = ['體溫量測次數', '平均體溫']
            break
          case 'bloodGlucose':
            measureCol = ['血糖量測次數', '用餐時間', '血糖']
            break
        }

        let aoa = [[...fixCol, ...measureCol]]
        for (const iterator of searchResult.value) {
          const fixData = [
            `${searchDate.value[0]}-${searchDate.value[1]}`,
            iterator.memberID,
            iterator.name,
          ]
          let measureData = []
          switch (searchType.value) {
            case 'bloodPressure':
              measureData = [
                iterator.frequency,
                iterator.avgRecord.systolic,
                iterator.avgRecord.diastolic,
                iterator.avgRecord.pulsePressureDifference,
                iterator.avgRecord.pulse,
                iterator.arrTotal,
                iterator.arrTotal,
                iterator.arrTotal,
              ]
              break
            case 'weight':
              measureData = [
                iterator.frequency,
                iterator.avgRecord.weight,
                iterator.avgRecord.bmi,
                iterator.avgRecord.bodyFat,
                iterator.avgRecord.bmr,
                iterator.avgRecord.visceralFat,
                iterator.avgRecord.muscleMass,
              ]
              break
            case 'SpO2':
              measureData = [iterator.frequency, iterator.avgRecord.SpO2]
              break
            case 'bodyTemperature':
              measureData = [
                iterator.frequency,
                iterator.avgRecord.bodyTemperature,
              ]
              break
            case 'bloodGlucose':
              measureData = [
                iterator.frequency,
                iterator.avgRecord.meal,
                iterator.avgRecord.bloodGlucose,
              ]
              break
          }
          aoa.push([...fixData, ...measureData])
        }
        let worksheet1 = XLSX.utils.aoa_to_sheet(aoa)
        const now = parseTime(Date.now(), '{y}{m}{d}{h}{i}{s}')
        export2Excel({
          worksheets: {
            sheet1: worksheet1,
          }, // 匯出excel的資料，key表示工作表名，value表示對應工作表的 sheet 資料，支援匯出多個工作表
          fileName: `HealthRecord_${now}`, // 匯出檔名
          type: 'xlsx', // 檔案匯出型別
        })
      }
      return {
        filter,
        handleSearch,
        searchResult,
        handelAction1,
        handelAction2,
        handelAction3,
        exportExcel,
        loading,
        searchType,
        exportEnable,
      }
    },
  }
</script>
<style lang="less"></style>
